import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'Components/Header/Header';
import { getMenus } from 'Chores/request';
import GP_SMALL_LOGO from '../../Public/Image/small-logo.svg';
import GP_BIG_LOGO from '../../Public/Image/big-logo.svg';
import { saveToken } from 'Slices/App/appSlice';
import { addMenus, addLoginUserId } from 'Slices/commonSlice';
import { VegaFlex, VegaSidenavGroup, VegaSidenavLink, VegaSidenav, VegaInput } from '@heartlandone/vega-react';
import '@heartlandone/vega/style/vega.css';
import { pathReplaceHandle, getMenuIcon, filterMenuTree, setAllUserAvatar, setAllDictType, setUserPermissions, setFromlocalStorage, getFromlocalStorage } from 'Chores/utils';
import { FE_ROUTES } from 'Chores/Api';
document.body.id = 'body-id';

const Layout = () => {
  const menus = useSelector((state) => state.common.menus);
  const token = useSelector((state) => state.app.token);
  const [originMenu, setOriginMenu] = useState([]);
  const [isShowMenuSearch, setMenuSearchShown] = useState(true);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (keycloak.authenticated) {
      sessionStorage.setItem('token', `${keycloak.token}`);
      dispatch(saveToken(keycloak.token));
      dispatch(addLoginUserId(keycloak.idTokenParsed.sub));

      setAllUserAvatar();
      setAllDictType();
      setUserPermissions(keycloak.idTokenParsed.sub);
    } else {
      setFromlocalStorage('loginRedirect', location, 20);
      navigate('/');
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (token) {
      const loadId = VegaLoader.load({
        containerSelector: '#body-id'
      });
      getMenus().then((response) => {
        const result = getMenuIcon(pathReplaceHandle(response));
        dispatch(addMenus(result));
        setOriginMenu(result);
        VegaLoader.close(loadId);
        const loginRedirect = getFromlocalStorage('loginRedirect');
        if (loginRedirect) {
          navigate(loginRedirect.pathname + loginRedirect.search);
        }
      });
    }
  }, [token]);

  const renderMenuData = (data = []) => {
    return (
      <div>
        {data.map((item) => {
          if (item?.children?.length) {
            return (
              <VegaSidenavGroup icon={item.icon} key={item.key} label={item.label}>
                {renderMenuData(item.children)}
              </VegaSidenavGroup>
            );
          } else if (item?.nodeType === 3) {
            return (
              <VegaSidenavLink
                selected={location.pathname.replace(FE_ROUTES.HOME, '') === item.newPath}
                icon={item.icon}
                onVegaClick={() => {
                  if (item?.extraProperties?.component === 'poker') {
                    window.open('https://poker.gprddigital.com');
                  } else {
                    navigate(`${FE_ROUTES.HOME}${item.newPath}`);
                    localStorage.setItem('_project', item.extraProperties?.projectId);
                  }
                }}
                key={item.key}
              >
                {item.label}
              </VegaSidenavLink>
            );
          }
        })}
      </div>
    );
  };

  const handleMenuSearch = (e) => {
    const res = filterMenuTree(e.target.value, originMenu);
    dispatch(addMenus(res));
  };

  return (
    <div className="App">
      <VegaFlex>
        <div className="vega-flex-shrink-0">
          <VegaSidenav
            footnote="Version 3.0.0"
            openByDefault="open"
            onVegaStateUpdate={(e) => {
              setMenuSearchShown(e.detail === 'close' ? false : true);
            }}
            headerConfig={{ expandLogo: GP_BIG_LOGO, collapseLogo: GP_SMALL_LOGO }}
          >
            <div className={isShowMenuSearch ? '' : 'v-hidden'}>
              <VegaInput
                id="menuSearch"
                autoComplete="off"
                prefixIcon="search"
                showClearIcon="true"
                size="small"
                placeholder="Search"
                onVegaChange={(e) => handleMenuSearch(e)}
              ></VegaInput>
            </div>
            {renderMenuData(menus)}
          </VegaSidenav>
        </div>
        <div className="v-min-w-min v-p-size-24 content v-bg-page vega-flex-grow">
          <VegaFlex direction="col" gap="size-24" style={{ height: '100%' }}>
            <Header />
            <Outlet />
            <div>
              {/* wait vega components update */}
              {/* <VegaAppFooter
              tosUrl="https://www.heartland.us/terms"
              privacyUrl="https://www.heartland.us/privacy-policy"
              showDisclaimer={false}
            ></VegaAppFooter> */}
            </div>
          </VegaFlex>
        </div>
      </VegaFlex>
    </div>
  );
};
export default Layout;
