import React, { lazy, Suspense } from 'react';
import '@heartlandone/vega/style/vega.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import KeycloakAuth from './KeycloakAuth';
import Layout from 'Containers/Layout/Layout';
import NotFound from 'Containers/NotFound/NotFound';
import { menuHandle, barToHump } from 'Chores/utils';
import { FE_ROUTES } from 'Chores/Api';
import ScrollTop from 'Components/ScrollTop';
import './App.scss';
import BackToSharkTankAfterLogin from 'Containers/BackToSharkTankAfterLogin';
import Home from './Containers/Home';

function App() {
  const menus = useSelector((state) => state.common.menus);
  const routeMenus = menuHandle(menus);
  const lazyLoad = (moduleName, menuProps) => {
    const Module = lazy(() => import(`./Containers/${moduleName}`));
    return <Module menuProps={menuProps} />;
  };

  // if component has descendant <Routes>  its path should be end with /* and handle not found page in its self <Routes>
  const hasSubRoutesComponents = ['DevCharts', 'DevMetrics', 'Permissions/Module', 'Permissions/Project', 'QaManagement', 'Retro', 'SprintGrid'];

  return (
    <Router>
      <ScrollTop>
        <Helmet
          titleTemplate="%s - globalpayment"
          defaultTitle="globalpayment"
          htmlAttributes={{ lang: 'i18n.language' }}
        >
          <meta name="description" content="globalpayment" />
        </Helmet>
        <Routes>
          <Route path={FE_ROUTES.KEYCLOAK_AUTH} element={<KeycloakAuth />} />
          <Route path={FE_ROUTES.LOGINJUMP} element={<BackToSharkTankAfterLogin />} />
          <Route path={FE_ROUTES.HOME} element={<Layout />}>
            <Route path={FE_ROUTES.HOME} element={<Home />} />
            {routeMenus?.map((item, key) => {
              const componentName = barToHump(item.component);
              let path = FE_ROUTES.HOME + item.newPath;
              if (hasSubRoutesComponents.includes(componentName)) {
                path += '/*';
              }
              return item.component ? (
                <Route
                  key={key}
                  path={path}
                  element={<Suspense>{lazyLoad(componentName, item)}</Suspense>}
                ></Route>
              ) : '';
            })}
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ScrollTop>
    </Router>
  );
}

export default App;
